import {PageNames, Paragraphs} from "../../data/options";
import {Stat} from "./Stat";
import {useEffect, useState} from "react";
import {
    inventoryDashboardSchema,
    inventoryDataMap,
    trendSchema
} from "../../data/chartSchemas";
import apiCall from "../base/call";
import {
    extractStatsArrangedByCol,
    prepareDistressedInvStat,
    assignValue
} from "../../utils/overviewPageTransforms";
import {Link} from "react-router-dom";


export const InventoryOverview = ({setAlert, filters, auth}) => {
    const [data, setData] = useState(inventoryDataMap)
    const [stats, setStats] = useState(inventoryDashboardSchema)

    useEffect (() => {
        for (const [key, value] of Object.entries(data)) {
            apiCall(setAlert, value.endpoint, filters, auth).then(resp => {
                if (resp && resp.data.length > 0) {
                    value.data = resp.data
                    setData({...data})
                } else {
                    value.data = false
                    setData({...data})
                }
            })
        }
    }, [filters.filter])

    useEffect(() => {
        console.log('Preparing of STATS')
        Object.keys(stats).forEach((key) => {stats[key].value = null; stats[key].trend = trendSchema}) // cleaning up empty stats

        if (data.inventory.data && data.inventoryDistress.data && data.exactInventoryValue.data) {
            const skuStock = prepareDistressedInvStat('_total_sku', '_distressed_sku', data.inventory.data, data.inventoryDistress.data)
            stats.skuStock = {...stats.skuStock, ...skuStock}
            const inventoryCost = prepareDistressedInvStat('_inv_cost', '_distressed_cost', data.inventory.data, data.inventoryDistress.data)
            stats.inventoryCost = {...stats.inventoryCost, ...inventoryCost}
            const inventoryQty = prepareDistressedInvStat('_inv_qty', '_distressed_qty', data.inventory.data, data.inventoryDistress.data)
            stats.inventoryQty = {...stats.inventoryQty, ...inventoryQty}

            const burnRate = extractStatsArrangedByCol(data.inventory.data, '_units_sold_per_day')
            stats.burnRate = {...stats.burnRate, ...burnRate}
            const dailyCogs = extractStatsArrangedByCol(data.inventory.data, '_daily_cogs')
            stats.dailyCogs = {...stats.dailyCogs, ...dailyCogs}
            const daysTurnover = extractStatsArrangedByCol(data.inventory.data, '_days_to_turnover')
            stats.daysTurnover = {...stats.daysTurnover, ...daysTurnover}

            const stockCost = assignValue(data.exactInventoryValue.data, '_exact_inv_cost')
            stats.stockCost = {...stats.stockCost, ...stockCost}
            const streetValue = assignValue(data.exactInventoryValue.data, '_exact_inv_price')
            stats.streetValue = {...stats.streetValue, ...streetValue}
        }

        setStats({...stats})
    }, [data])

    return (
        <div>
            <div className="flex flex-row items-center justify-between m-2">
                <p className='font-sans pl-2 mt-4 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {PageNames.INVENTORY.name}
                </p>
                <Link to={PageNames.INVENTORY.link}>
                    <button className="btn btn-sm w-full max-w-xs">See Details</button>
                </Link>
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-2 m-2 stats stats-vertical shadow">
                <Stat {...stats.skuStock}/>
                <Stat {...stats.inventoryCost}/>
                <Stat {...stats.inventoryQty}/>
                <Stat {...stats.burnRate}/>
                <Stat {...stats.dailyCogs}/>
                <Stat {...stats.daysTurnover}/>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-2 m-2 stats stats-vertical shadow">
                <Stat {...stats.stockCost}/>
                <Stat {...stats.streetValue}/>
            </div>
        </div>
    )
}