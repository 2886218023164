import {useEffect, useState} from "react";

const Field = props => {
    const [value, setValue] = useState('')

    useEffect(() => {
        let value = props.state[props.name] ? props.state[props.name] : ''
        value = typeof(value) === 'object' ? JSON.stringify(value) : value
        setValue(value);
    });

    const handleInput = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setValue(value)

        props.state[target.name] = value
        props.setState(props.state)
    }

    return (
        <label className={props.flexDirection ? props.flexDirection : "flex-row form-control w-full max-w-xs space-x-1"}>
            <div className="flex-none label">
                <span className="label-text">{props.labelName}</span>
            </div>
            <input className="flex-1 input input-sm input-bordered w-full max-w-xs"
                   type={props.type !== "text" ? props.type : "text"}
                   id={props.name}
                   placeholder={props.placeholder}
                   name={props.name}
                   value={value}
                   onChange={handleInput}/>
        </label>
    )
}

export default Field