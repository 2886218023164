export const colNamingLambda = (colName) => {
    let name = colName.header.id.split('_').slice(1).join(' ')
    name = String(name).charAt(0).toUpperCase() + String(name).slice(1)
    return name
}

export const colVisibilityFilter = (sampleRow) => {
    if (!sampleRow || sampleRow.length === 0) return
    let excludeCols = sampleRow.filter(x => x.includes('id'))
    excludeCols = excludeCols.reduce((a, v) => ({ ...a, [v]: false}), {})
    return excludeCols
}
