import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const TabsContainer = ({activeTab, setLastTab, setActiveTab, children}) => {

    const onSelect = (index, lastIndex, event) => {
        if (!activeTab.includes(index)) {
            setActiveTab([...activeTab, index]);
        }
        setLastTab(index);
        console.log('active tab state', activeTab);
    }

    return(
        <Tabs setUseDefaultStyles={false} forceRenderTabPanel className="" onSelect={onSelect}>
            <TabList role="tablist" className="tabs tabs-boxed my-4">
                {children.map(child => <Tab role="tab" className="tab" selectedClassName={"tab-active"}>{child.props.name}</Tab>)}
            </TabList>
            {children.map(child => <TabPanel>{child}</TabPanel>)}
        </Tabs>
    )
}

export default TabsContainer