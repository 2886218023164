import {Routes, Route} from "react-router-dom";

// import {HomePage} from "./HomePage";
// import {StoresPage} from "./StoresPage";
import {InventoryPage} from "./InventoryPage";
import {Navigation} from "./Navigation";
import {OverviewPage} from "./OverviewPage";
import {StorePerformancePage} from "./StorePerformancePage";
import {useEffect, useState} from "react";
import Auth from "./base/Auth";
import Alert from "./base/Alert";


function App() {
    console.log('render main')
    const [auth, setAuth] = useState({isLoggedIn: false, cls: new Auth()})
    const [alert, setAlert] = useState({type: 'alert', msg: '', visible: false})

    // check if the user is logged in already
    useEffect(() => {
    },[])

    return (
        <body className="App">
            <Navigation auth={auth} setAuth={setAuth}>
                <div className="mx-5">
                {alert.visible ? <Alert alert={alert} setAlert={setAlert}/> : null}
                </div>
                <Routes>
                    {/*<Route exact path="/" element={<HomePage/>}/>*/}
                    <Route exact path="overview" element={<OverviewPage auth={auth} setAlert={setAlert}/>}/>
                    {/*<Route path="stores-list" element={<StoresPage/>}/>*/}
                    <Route path="store-performance" element={<StorePerformancePage auth={auth} setAlert={setAlert}/>} />
                    <Route path="inventory" element={<InventoryPage auth={auth} setAlert={setAlert}/>} />
                </Routes>
            </Navigation>
    </body>
    );
}

export default App;
