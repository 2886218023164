import {Paragraphs} from "../../../data/options";
import React, {useEffect, useState} from "react";
import {Summary} from "./Summary";
import TabsContainer from "../../base/Tabs";
import {invReportSummary} from "../../../data/chartSchemas";
import {GenericReport} from "./GenericReport";
import apiCall from "../../base/call";
import {OrderExport} from "./OrderExport";

export const InventoryReports = ({setAlert, filters, auth, selectedProducts}) => {
    // Uploads all the data required for tabs. No post-processing
    const [refresh, setRefresh] = useState(false)
    const [lastTab, setLastTab] = useState(0)
    const [activeTab, setActiveTab] = useState([])

    const [baseStats, setBaseStats] = useState()
    const [generalData, setGeneralData] = useState(null)

    const [batchProducts, setBatchProducts] = useState(null)
    const [batchData, setBatchData] = useState(null)

    const [discountProducts, setDiscountProducts] = useState(null)
    const [discountData, setDiscountData] = useState(null)

    useEffect(() => {
        console.log('reloadingInvContainer')
        setActiveTab([lastTab])
    }, [])


    const handleFilterClick = (e) => {
        e.preventDefault()
        setActiveTab([lastTab])
        console.log('resetting active tab state to', lastTab)

        // moved selected products out of filters state, to prevent false trigger memo components above(brands graphs)
        const selected_pid = selectedProducts.map(idx => idx['_product_id']).join()
        const callFilter = {...filters, ['selected_pid']: selected_pid}
        apiCall(setAlert, 'rpc/overview_sales_profit_revenue', callFilter, auth).then(resp => {
            if (resp) setBaseStats(resp.data)
        })

        apiCall(setAlert, 'rpc/inv_brand_category_report', callFilter, auth).then(resp => {
            if (resp) setGeneralData(resp.data)
        })

        apiCall(setAlert, 'rpc/inv_selected_batch_pid', callFilter, auth).then(resp => {
            if (resp) setBatchProducts(resp.data)
        })
        apiCall(setAlert, 'rpc/inv_batch_report', callFilter, auth).then(resp => {
            if (resp) setBatchData(resp.data)
        })

        apiCall(setAlert, 'rpc/inv_selected_discount_pid', callFilter, auth).then(resp => {
            if (resp) setDiscountProducts(resp.data)
        })
        apiCall(setAlert, 'rpc/inv_discounts_report', callFilter, auth).then(resp => {
            if (resp) setDiscountData(resp.data)
        })

        setRefresh(!refresh)
    }

    console.log('rendering INV REPORTS')
    return (
        <div className="h-lvh">
            <div className="flex flex-row items-center justify-between ">
                <p className='font-sans pl-4 mt-8 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {Paragraphs.REPORTS.name}
                </p>
                <button className="btn btn-sm w-full max-w-xs" onClick={handleFilterClick}>Refresh Graphs</button>
            </div>
            <TabsContainer activeTab={activeTab} setLastTab={setLastTab} setActiveTab={setActiveTab}>
                <Summary name="Summary" activeTab={lastTab === 0} baseStats={baseStats} selectedProducts={selectedProducts} graphData={generalData}/>
                <GenericReport name="Categories" activeTab={lastTab === 1} refresh={refresh} mutation={'_master_category_name'} selectedProducts={selectedProducts} graphData={generalData}/>
                <GenericReport name="Subcategories" activeTab={lastTab === 2} refresh={refresh} mutation={'_category_name'} selectedProducts={selectedProducts} graphData={generalData}/>
                <GenericReport name="Brands" activeTab={lastTab === 3} refresh={refresh} mutation={'_brand_name'} selectedProducts={selectedProducts} graphData={generalData}/>
                <GenericReport name="Products" activeTab={lastTab === 4} refresh={refresh} mutation={'_product_name'} selectedProducts={selectedProducts} graphData={generalData}>
                    <OrderExport products={selectedProducts} filters={filters}/>
                </GenericReport>
                <GenericReport name="Discounts" activeTab={lastTab === 5} refresh={refresh} mutation={'_discount_name'} selectedProducts={discountProducts} graphData={discountData}/>
                <GenericReport name="By Batch" activeTab={lastTab === 6} refresh={refresh} mutation={'_batch_name'} selectedProducts={batchProducts} graphData={batchData}/>
            </TabsContainer>
        </div>
    )
}