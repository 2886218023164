import {ColChart, LineChart} from "../../charts/LineChart";
import React, {useEffect, useState} from "react";
import {invReportSummary} from "../../../data/chartSchemas";
import {groupByaggregateBy, groupAggregateAllProducts} from "../../../utils/dataTransforms";
import {Table} from "../../charts/Table";
import {colNamingLambda} from "../../../utils/tableLambdas";


export const GenericReport = ({activeTab, mutation, selectedProducts, graphData, children}) => {
    //Tabs Categories, Subcategories, Brands, Products
    // Groups by selected products by specific case
    // Pipes graph data as is
    const [graph, setGraph] = useState(invReportSummary)
    const [rowSelection, setRowSelection] = useState({})
    const [products, setProducts] = useState([])

    const passAsIs = ['_discount_name', '_product_name', '_batch_name']

    useEffect(() => {
        if (!activeTab || !graphData) return  // load only if your tab is active
        console.log('call generic effect')

        const aggregatedProducts = passAsIs.includes(mutation) ? selectedProducts : groupAggregateAllProducts(selectedProducts, mutation)
        const unitsSold = groupByaggregateBy(graphData, '_date', mutation, '_units_sold')
        const marginPct = groupByaggregateBy(graphData, '_date', mutation, '_avg_margin_pct')

        setProducts(aggregatedProducts)
        setGraph({['unitsSold']: unitsSold, ['marginPct']: marginPct})
    }, [graphData, activeTab])

    const colVisibilityFilter = (sampleRow) => {
        if (!sampleRow || sampleRow.length === 0) return
        let excludeCols = sampleRow.filter(x => ['_sku'].indexOf(x) > -1)
        excludeCols = excludeCols.reduce((a, v) => ({ ...a, [v]: false}), {})
        return excludeCols
    }

    if (!activeTab) return null
    console.log('call Generic Report with mutation', mutation)
    return (
        <div className="">
            <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-4">
                <ColChart title={{text: 'Units Sold'}}
                           yAxisName='USD'
                           data={graph.unitsSold}/>
                <LineChart title={{text: 'Margin %'}}
                           yAxisName='pct'
                           data={graph.marginPct}/>
            </div>
            <div className="">
                {children}
                <Table data={products} filtering={false} selection={true} rowSelection={rowSelection} setRowSelection={setRowSelection}
                   colVisibilityFilter={colVisibilityFilter} colNamingLambda={colNamingLambda}/>
            </div>
        </div>
    )
}