import Select from 'react-select'
import {LineChart} from "../charts/LineChart";
import {memo, useEffect, useState} from "react";
import apiCall from "../base/call";
import {txCategoryFields} from "../../data/chartSchemas";
import PieChart from "../charts/PieChart";
import {groupByaggregateBy, preparePieSum} from "../../utils/dataTransforms";

const BrandSales = memo(({setAlert, filters, activeTab, auth}) => {
    const [txData, setTxData] = useState(txCategoryFields)
    const [selectedOptions, setSelectedOptions] = useState([])
    const [options, setOptions] = useState([])

    useEffect(() => {
        console.log('call BS1')
        if (!activeTab) return

        let endpoint = 'rpc/sp_brand_sales'
        apiCall(setAlert, endpoint, filters, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                let units = groupByaggregateBy(dt, '_period', '_brand_name', '_units_sold')
                let revenue = groupByaggregateBy(dt, '_period', '_brand_name', '_revenue')

                let mixUnits = preparePieSum(units.series, 'Units Sold')
                let mixRevenue = preparePieSum(revenue.series, 'Revenue')
                setTxData({units, revenue, mixUnits, mixRevenue})

                setOptions(units.series.map(x => ({ 'value':x.name, 'label': x.name })))
                console.log('call BS2')
            }
        });
        console.log('call BS2')
    }, [activeTab, filters.filter]);

    const filtered = () => {
        if (selectedOptions.length === 0) return txData
        const selectedOptionsArray = selectedOptions.map(x => x.value)
        let selection = txCategoryFields
        selection.units.series = txData.units.series.filter( (obj) => selectedOptionsArray.includes(obj.name) )
        selection.revenue.series = txData.revenue.series.filter( (obj) => selectedOptionsArray.includes(obj.name) )
        selection.mixUnits.data = txData.mixUnits.data?.filter( (obj) => selectedOptionsArray.includes(obj.name) )
        selection.mixRevenue.data = txData.mixRevenue.data?.filter( (obj) => selectedOptionsArray.includes(obj.name) )
        return selection
    }

    const handleSelect = event => {
        setSelectedOptions(event)
    }

    if (!activeTab) return null
    console.log('rendering BS')
    return(
        <div className="flex flex-row space-x-4 m-2 mt-4">
            <div className="flex-initial w-64 space-y-2">
                <p className="font-sans pl-2 inline-block text-1xl sm:text-2xl font-bold text-slate-900 tracking-tight dark:text-slate-200">Select Data</p>
                <Select
                isMulti
                name="brands"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleSelect}
                closeMenuOnSelect={false}
                menuIsOpen={true}
                />
            </div>

            <div className="flex-1 min-w-0 grid grid-cols-2">
                <PieChart title={{text: 'Sales Mix (Units)'}}
                          data={filtered().mixUnits}/>
                <PieChart title={{text: 'Sales Mix (Revenue)'}}
                          data={filtered().mixRevenue}/>
                <LineChart title={{text: 'Unit Sales'}}
                           yAxisName='Count'
                           data={filtered().units}/>
                <LineChart title={{text: 'Revenue'}}
                           yAxisName='USD'
                           data={filtered().revenue}/>
            </div>
        </div>
    )
});

export default BrandSales