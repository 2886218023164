import {LineChart} from "../charts/LineChart";
import {memo, useEffect, useState} from "react";
import apiCall from "../base/call";
import {dataSchema, txRelatedFields, itemRelatedFileds} from "../../data/chartSchemas";
import {mapData} from "../../utils/dataTransforms";

const GrowthGraphs = memo(({setAlert, filters, activeTab, auth}) => {
    const [txData, setTxData] = useState(txRelatedFields)
    const [marginData, setMarginDt] = useState(dataSchema)
    const [customersData, setCustData] = useState(dataSchema)
    const [itemData, setItemSata] = useState(itemRelatedFileds)

    useEffect(() => {
        console.log('call GG1')
        if (!activeTab) return  // load only if your tab is active

        apiCall(setAlert, 'rpc/sp_tx_customer_revenue_tiket', filters, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                let netSales = mapData(dt, '_period', {'Revenue':'_revenue', 'Rolling Avg':'_rolling_revenue'})
                let transactions = mapData(dt, '_period', {'Transactions':'_transactions', 'Rolling Avg':'_rolling_transactions'})
                let avgTicket = mapData(dt, '_period', {'Sales Qty':'_avg_ticket', 'Rolling Avg':'_rolling_ticket'})
                let customers = mapData(dt, '_period', {'Unique Customers':'_unique_customers', 'Rolling Avg':'_rolling_customers'})
                setTxData({netSales, transactions, avgTicket, customers})
            }
        });

        apiCall(setAlert, 'rpc/sp_gross_margin', filters, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                let margin = mapData(dt, '_period', {'Margin %':'_margin_pct', 'Rolling Avg':'_rolling_pct_margin'})
                setMarginDt(margin)
            }
        });

        apiCall(setAlert, 'rpc/sp_new_customer', filters, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                let newCustomers = mapData(dt, '_period', {'New Customers':'_new_customers', 'Rolling Avg':'_rolling_new_customers'})
                setCustData(newCustomers)
            }
        });

        apiCall(setAlert, 'rpc/sp_tx_item_count', filters, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                let singleItem = mapData(dt, '_period', {'Single Itm Transactions':'_single_item', 'Rolling Avg':'_rolling_new_customers'})
                let multiItem = mapData(dt, '_period', {'Multi Itm Transactions':'_multi_item', 'Rolling Avg':'_rolling_new_customers'})
                setItemSata({singleItem, multiItem})
            }
        });
        console.log('call GG2')
    }, [filters.filter, activeTab])

    if (!activeTab) return null

    let finalCount = txData.netSales.count + txData.transactions.count + txData.avgTicket.count + txData.customers.count
        + itemData.singleItem.count + itemData.multiItem.count + customersData.count + marginData.count
    let alert = {type: 'alert', msg: 'Total datapoints count GG: ' + finalCount, visible: true}
    setAlert(alert)
    console.log('rendering GG')
    return(
        <div className="columns-2">
            <LineChart title={{text: 'Net Sales'}}
                       yAxisName='USD'
                       data={txData.netSales}/>
            <LineChart title={{text: 'Transactions'}}
                       yAxisName='Count'
                       data={txData.transactions}/>
            <LineChart title={{text: 'Avg Ticket'}}
                       yAxisName='USD'
                       data={txData.avgTicket}/>
            <LineChart title={{text: 'Gross Margin'}}
                       yAxisName='USD'
                       data={marginData}/>
            <LineChart title={{text: 'Customers'}}
                       yAxisName='Count'
                       data={txData.customers}/>
            <LineChart title={{text: 'New Customers'}}
                       yAxisName='Count'
                       data={customersData}/>
            <LineChart title={{text: 'Single Item Transaction'}}
                       yAxisName='Count'
                       data={itemData.singleItem}/>
            <LineChart title={{text: 'Multi Item Transaction'}}
                       yAxisName='Count'
                       data={itemData.multiItem}/>
        </div>
    )
});

export default GrowthGraphs