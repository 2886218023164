import {LineChart} from "../charts/LineChart";
import {memo, useEffect, useState} from "react";
import apiCall from "../base/call";
import {txBehaviourFields, registerBehaviourFields} from "../../data/chartSchemas";
import {mapData, groupByaggregateBy} from "../../utils/dataTransforms";
import {RadioButton} from "../base/Selector";


const RevenueTxCustomerRow = ({revenue, tx, customer}) => {
    return(
        <div className="columns-3">
            <LineChart title={{text: 'Revenue'}}
                       yAxisName='USD'
                       data={revenue}/>
            <LineChart title={{text: 'Transactions'}}
                       yAxisName='Count'
                       data={tx}/>
            <LineChart title={{text: 'Customers'}}
                       yAxisName='Count'
                       data={customer}/>
        </div>
    );
}

const PurchaseBehaviour = memo(({setAlert, filters, activeTab, auth}) => {
    const [txData, setTxData] = useState(txBehaviourFields);
    const [regData, setRegistersData] = useState(registerBehaviourFields);
    const [selectedFilter, setSelectedFilter] = useState('Base Sales Statistics')

    useEffect(() => {
        console.log('call PB1')
        if (!activeTab) return

        apiCall(setAlert, 'rpc/sp_tx_behaviour_order_type', filters, auth).then(resp => {
            if (resp) {
                console.log('call PB2')
                let dt = resp.data
                let inStoreDt = dt.filter(x => x['_order_type'] === 'In Store')
                let inStoreRv = mapData(inStoreDt, '_period', {'Revenue': '_revenue'})
                let inStoreTx = mapData(inStoreDt, '_period', {'Transactions': '_transactions'})
                let inStoreCs = mapData(inStoreDt, '_period', {'Customers': '_unique_customers'})

                let pickupDt = dt.filter(x => x['_order_type'] === 'In-Store Pickup')
                let pickRv = mapData(pickupDt, '_period', {'Revenue': '_revenue'})
                let pickTx = mapData(pickupDt, '_period', {'Transactions': '_transactions'})
                let pickCs = mapData(pickupDt, '_period', {'Customers': '_unique_customers'})

                let deliveryDt = dt.filter(x => x['_order_type'] === 'Delivery')
                let deliveryRv = mapData(deliveryDt, '_period', {'Revenue': '_revenue'})
                let deliveryTx = mapData(deliveryDt, '_period', {'Transactions': '_transactions'})
                let deliveryCs = mapData(deliveryDt, '_period', {'Customers': '_unique_customers'})

                let revenueComparison = groupByaggregateBy(dt, '_period', '_order_type', '_revenue')
                let transactionsComparison = groupByaggregateBy(dt, '_period', '_order_type', '_transactions')
                let customersComparison = groupByaggregateBy(dt, '_period', '_order_type', '_unique_customers')


                setTxData({
                    inStoreRv, inStoreTx, inStoreCs,
                    pickRv, pickTx, pickCs,
                    deliveryRv, deliveryTx, deliveryCs,
                    revenueComparison, transactionsComparison, customersComparison})
            }
        });

        apiCall(setAlert, 'rpc/sp_tx_stats_by_register', filters, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                let netSales = groupByaggregateBy(dt, '_period', '_register_name', '_revenue')
                let transactions = groupByaggregateBy(dt, '_period', '_register_name', '_transactions')
                let avgTicket = groupByaggregateBy(dt, '_period', '_register_name', '_avg_ticket')
                let customers = groupByaggregateBy(dt, '_period', '_register_name', '_unique_customers')
                setRegistersData({netSales, transactions, avgTicket, customers})
            }
        });
        console.log('call PB2')
    }, [activeTab, filters.filter])


    if (!activeTab) return null
    console.log('rendering PB')
    return(
        <div>
            <p className="font-sans inline-block text-1xl sm:text-2xl font-bold text-slate-900 tracking-tight dark:text-slate-200">Select View Mode</p>
            <RadioButton options={['Base Sales Statistics', 'Compare by Method', 'By-Register Performance']}
                         selected={selectedFilter}
                         setSelected={setSelectedFilter}/>

            <div className={selectedFilter === 'Base Sales Statistics' ? "" : "hidden"}>
                <h2 className="flex basis-1/8 text-xl font-bold tracking-wide p-4">In-Store</h2>
                <RevenueTxCustomerRow revenue={txData.inStoreRv}
                                      tx={txData.inStoreTx}
                                      customer={txData.inStoreCs}/>

                <h2 className="flex basis-1/8 text-xl font-bold tracking-wide p-4">In-Store Pickup</h2>
                <RevenueTxCustomerRow revenue={txData.pickRv}
                                      tx={txData.pickCs}
                                      customer={txData.pickTx}/>

                <h2 className="flex basis-1/8 text-xl font-bold tracking-wide p-4">Delivery</h2>
                <RevenueTxCustomerRow revenue={txData.deliveryRv}
                                      tx={txData.deliveryTx}
                                      customer={txData.deliveryCs}/>
            </div>

            <div className={selectedFilter === 'Compare by Method' ? "" : "hidden"}>
                <h2 className="flex basis-1/8 text-xl font-bold tracking-wide p-4">Order Methods Comparison</h2>
                <RevenueTxCustomerRow revenue={txData.revenueComparison}
                                      tx={txData.transactionsComparison}
                                      customer={txData.customersComparison}/>
            </div>

            <div className={selectedFilter === 'By-Register Performance' ? "": "hidden"}>
                <h2 className="flex basis-1/8 text-xl font-bold tracking-wide p-4">Registers Performance</h2>
                <div className="columns-2">
                    <LineChart title={{text: 'Revenue'}}
                               data={regData.netSales}/>
                    <LineChart title={{text: 'Transactions'}}
                               data={regData.transactions}/>
                    <LineChart title={{text: 'Avg Ticket'}}
                               data={regData.avgTicket}/>
                    <LineChart title={{text: 'Unique Customers'}}
                               data={regData.customers}/>
                </div>
            </div>
        </div>
    )
});

export default PurchaseBehaviour