import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsBoost from "highcharts/modules/boost";
import {memo} from "react";
HighchartsBoost(Highcharts);


const ColChart = memo(({title, subtitle, yAxisName, data}) => {
    const options = {
        chart: {
            type: 'column'
        },
        boost: {
            useGPUTranslations: true,
            // Chart-level boost when there are more than 20 series in the chart
            seriesThreshold: 20
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '0.8em',
                        textOutline: 'none',
                        opacity: 0.6,
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 15
                    }
                }
            },
            series: {
                borderWidth: 0,
                groupPadding: 0.1,
                pointPadding: 0
            }
        },
        title: title,
        subtitle: subtitle,
        yAxis: {
            min: 0,
            title: {
                text: yAxisName ? yAxisName : 'Value'
            },
            stackLabels: {
                enabled: true
            }
        },
        ...data
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
});

const LineChart = ({title, subtitle, yAxisName, data}) => {

    const options = {
        chart: {
            type: 'line'
        },
        boost: {
            useGPUTranslations: true,
            // Chart-level boost when there are more than 20 series in the chart
            seriesThreshold: 20
        },
        title: title,
        subtitle: subtitle,
        yAxis: {
            min: 0,
            title: {
                text: yAxisName ? yAxisName : 'Value'
            },
            stackLabels: {
                enabled: true
            }
        },
        ...data
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
};

export {ColChart, LineChart};