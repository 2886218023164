import {trendSchema} from "../../data/chartSchemas";

export const Stat = ({title, description, value, unit, trend=null}) => {
    let trendDirection = trend?.direction ? '\u2197' : '\u2198'
    if (trend?.direction === null) trendDirection = ''

    let trendUnit = trend?.unit ? trend?.unit : ''
    let trendValue =  trend?.points + trendUnit + ' ' + trendDirection
    let trendStyle = trend?.direction ? 'stat-figure text-primary font-semibold text-green-400' : 'stat-figure text-primary font-semibold text-rose-400'

    return (
        // <div className="stats stats-vertical lg:stats-horizontal shadow">

            <div className="stat border-t">
                {trend?.points === 0 && trend?.direction === false ? null : <div className={trendStyle}>{trendValue}</div>}
                <div className="stat-title">{title}</div>
                <div className="stat-value">{value}
                    <span className={'text-lg'}>
                        {unit}
                    </span>
                </div>
                {description ? <div className="stat-desc text-wrap">{description}</div> : null}
            </div>

        // </div>
    )
}
