import heatmap from "highcharts/modules/heatmap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {memo} from "react";
heatmap(Highcharts);

const HeatMap = memo( ({title, subtitle, xAxis, yAxis, data, chartOptions, isActive}) => {

    const options = {
        chart: {
            type: 'heatmap',
            marginTop: 80,
            marginBottom: 40,
            plotBorderWidth: 1,
            height: 500,
            scrollablePlotArea: {
                minHeight: yAxis.length * 20,
                scrollPositionY: 0
            }
        },

        title: title,

        xAxis: {
            categories: xAxis,
            opposite: true
        },

        yAxis: {
            categories: yAxis,
            title: null,
            reversed: true,
            lineWidth: 1,
            tickWidth: 1
        },

        accessibility: {
            point: {
                descriptionFormat: '{(add index 1)} ' +
                    '{series.xAxis.categories.(x)} sales ' +
                    '{series.yAxis.categories.(y)}, {value}.'
            }
        },

        colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: Highcharts.getOptions().colors[0]
        },

        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 10,
            verticalAlign: 'top',
            y: 85,
            symbolHeight: 280
        },

        tooltip: {
            format: '<b>{series.xAxis.categories.(point.x)}</b> <br>' +
                '<b>{point.value}</b> on <br>' +
                '<b>{series.yAxis.categories.(point.y)}</b>'
        },

        series: [{
            name: 'Sales',
            borderWidth: 0,
            data: data,
            dataLabels: {
                enabled: true,
                color: '#000000'
            }
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 800
                },
                chartOptions: chartOptions
            }]
        }

    }
    if (!isActive) return null
    console.log('rendering HeatMap', isActive)
    return (
        <div className="height: 400px;">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
});

export default HeatMap