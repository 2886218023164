const dataSchema = {
    yAxis:{},
    xAxis: {},
    series: [],
    count: 0
}

const txRelatedFields = {
    netSales: dataSchema,
    transactions: dataSchema,
    avgTicket: dataSchema,
    customers: dataSchema}

const invReportSummary = {
    unitsSold: dataSchema,
    marginPct: dataSchema
}

const itemRelatedFileds = {singleItem: dataSchema, multiItem: dataSchema}

const shiftOptions = {'1h Shifts': 'rpc/sp_staff_1h_shifts', '3h Shifts': 'rpc/sp_staff_3h_shifts' , '4h Shifts': 'rpc/sp_staff_4h_shifts'}

const trendSchema = {points: 0, direction: false}
const statSchema = {main: null, trend: trendSchema}
const overviewDashboardSchema = {
    stores: {title:'Active Stores', description: 'Current month', value: null, trend: trendSchema},
    ticket: {title:'Avg Daily Net Ticket', unit: '$', value: null, trend: trendSchema},
    margin: {title:'Avg Margin', description: 'Avg Daily Margin, MoM', unit: '$', value: null, trend: trendSchema},
    marginPct: {title:'Avg Margin Pct', description: 'Avg Daily Margin Percent, MoM', unit: '%', value: null, trend: trendSchema},
    transactions: {title:'Avg Transactions', description: 'Daily Transactions, MoM', value: null, trend: trendSchema},
    netSales: {title:'Avg Net Sales', description: 'Daily Net Sales, MoM', unit: '$', value: null, trend: trendSchema},
    customers: {title:'Avg Customers', description: 'Unique Customers, MoM', value: null, trend: trendSchema},
    loyalCustomers: {title:'Avg Loyal Customer', description: 'Loyal Customers (shopped 6+ times in the last 180 days, and have also shopped in the last 30d), MoM', value: null, trend: trendSchema},
    newCustomers: {title:'Avg New Customers', description: 'New Customers (shopped first time this month, since previous 3 month absence), MoM', value: null, trend: trendSchema},
    lostCustomers: {title:'Avg Lost Customers', description: 'Lost Customers (Did not shopped this month, but was hitting previous 3 month), MoM', value: null, trend: trendSchema},
    returnedCustomers: {title:'Avg Customer Returns', description: 'Avg number of return for a single customer per month, MoM', value: null, trend: trendSchema},
    debtRatio: {title:'Avg Debt to Income Ratio', description: 'Avg daily Net Sales / Debt over Month period ', value: null, trend: trendSchema},
    labourRatio: {title:'Avg Staffing Cost VS Sales', description: '% of Avg Daily Staffing cost to Sales over Month period', unit: '%', value: null, trend: trendSchema},
    liabilityRatio: {title:'Avg Cash vs Liabilities', description: '', unit: '%', value: null, trend: trendSchema}
}

const inventoryDashboardSchema = {
    skuStock: {title:'Total In-Stock SKUs', description: 'Current qty of instock SKUs', value: null, trend: trendSchema},
    inventoryCost: {title:'Total Inventory Cost', description: 'Current cost of inventory in stock', unit: '$', value: null, trend: trendSchema},
    inventoryQty: {title:'Total Units in Stock', description: 'Current amount of inventory pieces in stock', unit: 'pcs', value: null, trend: trendSchema},
    burnRate: {title:'Avg Burn Rate', description: 'Amount of items sold per day. Based on monthly performance', unit: 'pcs', value: null, trend: trendSchema},
    dailyCogs: {title:'Avg Daily COGS', description: 'Cost of good sold per day. Based on monthly performance ', unit: '$', value: null, trend: trendSchema},
    daysTurnover: {title:'Avg Days to Turnover', description: 'Qty of days needed to turnover all inventory (cost speaking). Based on monthly performance', unit: 'days', value: null, trend: trendSchema},
    stockCost: {title:'Exact Inventory Cost', description: 'Sum of unit costs non-avaraged by batches', unit: '$', value: null, trend: trendSchema},
    streetValue: {title:'Street Value', description: 'Sum of unit price', unit: '$', value: null, trend: trendSchema}
}

const inventoryReportSchema = {
    base: {
        brands: {title:'Brands', value: null, trend: trendSchema},
        categories: {title:'Categories', value: null, trend: trendSchema},
        subcategories: {title:'Subcategories', value: null, trend: trendSchema},
        sku: {title:'SKUs', value: null, trend: trendSchema},
        skuInStock: {title:'SKUs In Stock', value: null, trend: trendSchema},
        unitsInStock: {title:'Units In Stock', value: null, trend: trendSchema}
    },
    consumption: { avgBurnRate: {title:'Avg Burn Rate', description: 'For the last 90d period', value: null, trend: trendSchema},
        avgBurnRatePeriod: {title:'Avg Burn Rate (Period)', description: 'For the user-selected period', value: null, trend: trendSchema},
        maxDaysLeft: {title:'Max Days Left', value: null, trend: trendSchema},
        projectedSales90d: {title:'Projected Sales', description: 'Based on 90d burn rate stat', value: null, trend: trendSchema},
        projectedSales30d: {title:'Projected Sales (Period)', description: 'Based on user-selected period burn rate stat', value: null, trend: trendSchema},
        minDaysLeft: {title:'Min Days Left', value: null, trend: trendSchema}
    },
    sales: {
        revenue: {title:'Total Revenue', value: null, trend: trendSchema},
        netProfit: {title:'Total Net Profit', value: null, trend: trendSchema},
        unitsSold: {title:'Total Units Sold', description: 'for selected time period', value: null, trend: trendSchema},
        pctRevenue: {title:'% of Revenue', value: null, trend: trendSchema},
        pctProfit: {title:'% of Net Profit', value: null, trend: trendSchema},
        pctSales: {title:'% of Sales', value: null, trend: trendSchema},
        pctMargin: {title:'Margin %', value: null, trend: trendSchema}
    }
}

const overviewDataMap = {sales: {endpoint: 'rpc/overview_performance_daily_avg', data: false},
    margin: {endpoint: 'rpc/overview_margin_daily_avg', data: false},
    loyalCustomers: {endpoint: 'rpc/overview_loyal_customers', data: false},
    newCustomers: {endpoint: 'rpc/overview_new_customers', data: false},
    lostCustomers: {endpoint: 'rpc/overview_lost_customers', data: false},
    returnCustomers: {endpoint: 'rpc/overview_returned_customers', data: false},
    labour: {endpoint: 'rpc/overview_labour_daily_avg', data: false}
}

const inventoryDataMap = {
    inventory: {endpoint: 'rpc/overview_inventory', data: false},
    inventoryDistress: {endpoint: 'rpc/overview_distressed_inventory', data: false},
    exactInventoryValue: {endpoint: 'rpc/exact_inventory_value', data: false},
}

const heatMapSchema = {title: 'No Title', xAxis: [], yAxis: [], series: []}

const dayNameMap = {1:"Monday", 2:"Tuesday", 3:"Wednesday", 4:"Thursday", 5:"Friday", 6:"Saturday", 7:"Sunday"}

const pivotedStaffingHeatMaps = {
    avgEmployeePerShift: heatMapSchema,
    txPerEmployee: heatMapSchema,
    minPerTx: heatMapSchema,
    avgTransactions: heatMapSchema
}

const txCategoryFields = {
    mixUnits: dataSchema,
    mixRevenue: dataSchema,
    units: dataSchema,
    revenue: dataSchema}

const txBehaviourFields = {
    inStoreRv: dataSchema,
    inStoreTx: dataSchema,
    inStoreCs: dataSchema,
    pickRv: dataSchema,
    pickTx: dataSchema,
    pickCs: dataSchema,
    deliveryRv: dataSchema,
    deliveryTx: dataSchema,
    deliveryCs: dataSchema,
    revenueComparison: dataSchema,
    transactionsComparison: dataSchema,
    customersComparison: dataSchema}

const registerBehaviourFields = {
    netSales: dataSchema,
    transactions: dataSchema,
    avgTicket: dataSchema,
    customers: dataSchema}

export {invReportSummary, inventoryReportSchema, inventoryDashboardSchema, inventoryDataMap, overviewDataMap, overviewDashboardSchema,
    trendSchema, statSchema, dataSchema, txRelatedFields, itemRelatedFileds, txCategoryFields, txBehaviourFields,
    registerBehaviourFields, heatMapSchema, pivotedStaffingHeatMaps, shiftOptions, dayNameMap}