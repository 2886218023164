const Alert = ({alert, setAlert}) => {
    const msgParser = (msg) => {
        let parsedMsg = msg
        if (msg.includes('401')) parsedMsg = 'Not authorized, please login to continue.'
        return parsedMsg
    }

    return (
        <div role="alert" className={alert.type.style} onClick={() =>  setAlert({...alert, visible: false})}>
            {alert.type.icon}
            <span>{msgParser(alert.msg)}</span>
        </div>
    )
}

export default Alert