import {MobileNav} from "./MobileNav";
import { Link } from "react-router-dom";
import SignIn from "./SignIn";
import {useState} from "react";
import Alert from "./base/Alert";
import {PageNames} from "../data/options";

export const Navigation = ({auth, setAuth, children}) => {
    const [alert, setAlert] = useState({type: undefined, msg: '', visible: false})
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const handleLoginClick = (email, password) => {
        if (auth.cls) {
            auth.cls.login(email, password).then(resp => {
                if (resp.message === 'Success') {
                    setAuth({...auth, 'isLoggedIn': true})
                    setIsLoggedIn(true)
                } else {
                    let alert = {type: 'alert alert-error', msg: resp.message, visible: true}
                    setAlert(alert)
                    setAuth({...auth, 'isLoggedIn': false});
                }
            });
        }
    };

    const handleLogoutClick = () => {
        if (auth) {
            auth.cls.logout()
            setIsLoggedIn(false)
        }
        setAuth({...auth, 'isLoggedIn': false})
    };

    return (

        <div className="drawer lg:drawer-open">
            <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content flex flex-col">
                <MobileNav/>
                {alert.visible ? <Alert alert={alert} setAlert={setAlert}/> : null}
                {children}
            </div>

            <div className="drawer-side">
                <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label>
                <ul className="menu p-4 w-50 min-h-full bg-base-200 text-base-content">
                    {/*<li><Link to={PageNames.HOME.link}>{PageNames.HOME.name}</Link></li>*/}
                    <li><Link to={PageNames.OVERVIEW.link}>{PageNames.OVERVIEW.name}</Link></li>
                    {/*<li><Link to={PageNames.STORES.link}>{PageNames.STORES.name}</Link></li>*/}
                    <li><Link to={PageNames.PERFORMANCE.link}>{PageNames.PERFORMANCE.name}</Link></li>
                    <li><Link to={PageNames.INVENTORY.link}>{PageNames.INVENTORY.name}</Link></li>
                    {
                        isLoggedIn ?
                        <li><button onClick={() => handleLogoutClick()}>{PageNames.LOGOUT.name}</button></li> :
                        <li><button onClick={()=>document.getElementById('signIn').showModal()}>{PageNames.LOGIN.name}</button></li>
                    }
                </ul>
            </div>
            <SignIn setUserEmailPassword={handleLoginClick}/>
        </div>
    )
}
