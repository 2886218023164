import DateRange from "./DateRange";
import {Selector} from "./Selector";
import {periodOptions} from "../../data/options";
import {Children} from "react";


const Filter = ({children, filters, setFilters}) => {

    return (
        <div className="flex flex-wrap justify-end base-1/2 space-x-4 space-y-2">
            <div className="flex-initial ml-4 mt-2 max-h-12 min-w-64 border rounded-lg border-gray-300">
                <DateRange state={filters} setState={setFilters} labelName='Select Dates'/>
            </div>
            <div>
                <Selector options={periodOptions} state={filters} setState={setFilters} name='period' labelName='View by:'></Selector>
            </div>
            {Children.map(children, child => <div>{child}</div>)}
        </div>)
};

export {Filter};